import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Resolvable from './Resolvable'
import 'rsuite/dist/rsuite.min.css'
import {Container, Content, CustomProvider, Header, Panel} from 'rsuite'

const Index = () => {
    return <Container>
        <Header><h1 style={{textAlign:'center'}}>PropStats</h1></Header>
        <Content>
            <Panel style={{textAlign:'center'}}>Перейди по полученной ссылке, чтобы просмотреть количество энтити.<br/>Результаты хранятся в течение часа.</Panel>
        </Content>
    </Container>
}

function App() {
  return (
      <CustomProvider theme='dark'>
          <BrowserRouter>
              <Routes>
                  <Route path='/:id' element={<Resolvable/>}/>
                  <Route path="*" element={<Index/>}/>
              </Routes>
          </BrowserRouter>
      </CustomProvider>
  )
}

export default App