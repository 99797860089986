import React, {useEffect, useState} from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import {
    Button, Carousel, Container,
    Content, Header,
    Input, Loader,
    Message,
    Modal,
	Panel,
} from 'rsuite'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'
import ServerResponse from './ServerResponse'

ChartJS.register(ArcElement, Tooltip, Legend)

const Resolvable = () => {
    const { id } = useParams()
    const [statusCode, setStatusCode] = useState(0)
    const [errorMessage, setErrorMessage] = useState('')
    const [token, setToken] = useState(sessionStorage.getItem('tkn') || '')

    const [data, setData] = useState<ServerResponse | undefined>()
	const [dataByClass, setDataByClass] = useState<[any[], any[]]>([[], []])
	const [dataByOwner, setDataByOwner] = useState<[any[], any[]]>([[], []])

    const fetch = () => {
        axios.get('/api/get/' + id, {
            headers: {
                'Authorization': `Basic ${token}`
            }
        }).then((response) => {
			const byClassLabels = [], byClassValues = []
			for (const entry of response.data.byClass) {
				byClassLabels.push(entry[0])
				byClassValues.push(entry[1])
			}
			setDataByClass([ byClassLabels, byClassValues ])
			const byOwnerLabels = [], byOwnerValues = []
			for (const entry of response.data.byOwner) {
				byOwnerLabels.push(entry[0])
				byOwnerValues.push(entry[1])
			}
			setDataByOwner([ byOwnerLabels, byOwnerValues ])
            setData(response.data)
            sessionStorage.setItem('tkn', token)
        }).catch((err) => {			
            if (err.response) {
                setStatusCode(err.response.status)
                if (err.response.status === 404)
                    setErrorMessage('Запись с таким ключом не найдена. Возможно, она была создана более 1 часа назад и уже удалена')
                else if (err.response.status !== 401) setErrorMessage(err.response.data)
            } else {
                setErrorMessage('Нет соединения с сервером')
                setStatusCode(-1)
                console.error(err)
            }
        })
    }
    useEffect(fetch, [])

    return (
        <>
            <Container style={{backgroundColor:'var(--rs-bg-card)',height:'100vh'}}>
                <Header><h1 style={{textAlign:'center'}}>PropStats</h1></Header>
                <Content>
                    {errorMessage && statusCode !== 200 &&
                        <Message style={{marginTop:10}} showIcon type='error' header='Произошла ошибка при загрузке'>{errorMessage}</Message>
                    }
                    {data && (<>
                        <h3 style={{textAlign:'center'}}>Итого: {data.total} энтити</h3>
                        <div style={{display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center'}}>

                            <Carousel style={{width:700,height:800}}>
								<Panel style={{width:700,height:800}}>
									<Pie
										data={{
											labels: dataByClass[0],
											datasets: [
												{
													label: 'Статистика по классам',
													data: dataByClass[1],
													backgroundColor: dataByClass[0].map(() => `rgba(${Math.random()*255}, ${Math.random()*255}, ${Math.random()*255}, 0.2)`),
													borderColor: dataByClass[0].map(() => `rgba(${Math.random()*255}, ${Math.random()*255}, ${Math.random()*255}, 0.2)`),
												},
											],
										}}
									/>
								</Panel>
								<Panel style={{width:700,height:800}}>
									<Pie
										data={{
											labels: dataByOwner[0],
											datasets: [
												{
													label: 'Статистика по владельцам',
													data: dataByOwner[1],
													backgroundColor: dataByOwner[0].map(() => `rgba(${Math.random()*255}, ${Math.random()*255}, ${Math.random()*255}, 0.2)`),
													borderColor: dataByOwner[0].map(() => `rgba(${Math.random()*255}, ${Math.random()*255}, ${Math.random()*255}, 0.2)`),
												},
											],
										}}
									/>
								</Panel>
                            </Carousel>
                        </div>
                    </>)}
                </Content>
            </Container>
            <Modal size='xs' open={statusCode === 401}>
                <Modal.Header>
                    <Modal.Title>Вход</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label>Токен:</label>
                    <Input type="password" value={token} onChange={setToken}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => {
                        setStatusCode(0)
                        setData(undefined)
                        setErrorMessage('')
                        fetch()
                    }}>Войти</Button>
                </Modal.Footer>
            </Modal>
            {!(data || statusCode) && <Loader center backdrop content="Загрузка..." vertical size="lg"/>}
        </>
    )
}
export default Resolvable